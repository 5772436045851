import $ from 'jquery';
// import { gsap } from 'gsap';
import imagesLoaded from 'imagesloaded';

imagesLoaded.makeJQueryPlugin($);

const Fx = {
    init() {
        this.Intro();
    },
    Intro() {
        const $pageHeader = $('.page-header');

        $pageHeader.imagesLoaded({ background: '.page-header__image' }, () => {
            $('body').addClass('is-loaded');
        });
    },
};

export default Fx;
